export default defineNuxtPlugin(async (nuxtApp) => {
    if (process.client) {
        const createCacheVersion = async () => {   
            const isPWA = window.matchMedia('(display-mode: standalone)').matches;
            if(isPWA){
                if ('serviceWorker' in navigator) {
                    window.addEventListener('load', () => {
                        navigator.serviceWorker.register('/service-worker.js')
                        .then(registration => {
                            console.log('Service Worker registered with scope:', registration.scope);
                        })
                        .catch(error => {
                            console.error('Service Worker registration failed:', error);
                        });
                    }); 
                }
            }
        }

        return {
            provide: {
                createCacheVersion,
            },
        };
    }
});